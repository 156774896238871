import React, { Component } from "react";
import Header from "../layout/Header";
import Banner from "../Common/Banner";

export default class AboutChairman extends Component {
  render() {
    return (
      <div className="body_wrapper">
        <Header />
        <Banner title="Chairman's Message" image="banners/About.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl paddingtop-2">
            <div className="row">
              <div className="col-lg-3">
                <div className="ttm-featured-wrapper">
                  <div className="featured-thumbnail text-center">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        `/assets/images/aboutchairman/chairman.jpg`
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-9 justify" style={{ margin: "auto" }}>
                <div className="ttm-team-member-content shadow-box res-991-mt-30">
                  <div className="ttm-team-member-content shadow-box res-991-mt-30">
                    <div className="ttm-team-member-single-list">
                      <a
                        href="https://www.shoonyeka.com/bit-principal-production/"
                        target="_blank"
                      >
                        <h2 className="ttm-team-member-single-title">
                          Sri Hanumantharayappa
                        </h2>
                      </a>
                      <span className="ttm-team-member-single-position">
                        Chairman, KHIPT, RVS
                      </span>
                      <hr />
                      <p>
                        Kengal Hanumanthaiah Institute of Physiotherapy College
                        is one of the superior institutes in the state of
                        Karnataka imparting quality education in the field of
                        physiotherapy at under graduate level.Our vision is
                        based on hard work, open communication, a strong
                        emphasis on team work and high level of responsibility
                        .This visionary culture allows and emphasises our
                        students not only to adopt the present day challenges
                        but also individual responsibilities to the society and
                        our nation at large
                      </p>
                      <p>
                        The dedicated and committed faculty have ensured that
                        the students become competent, confident, se lf-reliant,
                        lifelong learners ready for the change and stand proudly
                        in the various aspects of physiotherapy
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 paddingtop-2 justify">
                <p>
                  I am confident that the students who aspire to join our
                  college will be provided with holistic education and a chance
                  to serve the society at large.
                </p>
                <p>With Best Regards</p>
                <p>
                  <b style={{ fontWeight: "bold" }}>Sri Hanumantharayappa</b>
                  <br />
                  Chairman, KHIPT, RVS
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
